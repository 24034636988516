import React, { Component } from "react";
import { withTranslation } from 'react-i18next'
import BounceLoader from "react-spinners/BounceLoader";

export class SpinnerLoading extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        let text = this.props.text;

        return (
            <React.Fragment>
                <BounceLoader color='#F3B64E' size={60} />
                <div style={{ color: '#fafafa', margin: '10px' }}>{text === "" ? "Preparing... " : this.props.t(text)}</div>
            </React.Fragment >
        );
    }
}

export default withTranslation('common')(SpinnerLoading);