import Actions from '../../actions';

const getDefaultState = () => ({
    isLoading: false,
    data: null,
    error: null
})

function address(state, action) {
    if (typeof state === 'undefined') return getDefaultState();
    switch (action.type) {
        case Actions.UPDATE_ADDRESS:
            return {
                isLoading: true,
                data: state.data,
                error: null
            }

        case Actions.UPDATE_ADDRESS_SUCCESS:
            return {
                isLoading: false,
                data: action.data,
                error: null,
            }

        case Actions.UPDATE_ADDRESS_FAILED:
            return {
                isLoading: false,
                data: null,
                error: action.error
            }

        case Actions.RESET_ADDRESS:
            return getDefaultState();

        default:
            return state;
    }
}

export default address;