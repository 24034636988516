import Actions from '../../actions';

const getDefaultState = () => ({
    isLoading: false,
    data: null,
    error: null
})

function trxBalance(state, action) {
    if (typeof state === 'undefined') return getDefaultState();
    switch (action.type) {
        case Actions.UPDATE_TRX_BALANCE:
            return {
                isLoading: true,
                data: state.data,
                error: null
            }

        case Actions.UPDATE_TRX_BALANCE_SUCCESS:
            return {
                isLoading: false,
                data: action.data,
                error: null,
            }

        case Actions.UPDATE_TRX_BALANCE_FAILED:
            return {
                isLoading: false,
                data: null,
                error: action.error
            }

        case Actions.RESET_TRX_BALANCE:
            return getDefaultState();

        default:
            return state;
    }
}

export default trxBalance;