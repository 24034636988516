export const PRODUCTION = "PRODUCTION";
export const DEVELOPMENT = "DEVELOPMENT";

export const MAINNET = "Mainnet";
export const TESTNET = "Nile Testnet";

export const TRONGRID_API = "https://api.trongrid.io";
export const TRON_STACK_API = "https://api.tronstack.io";
export const NILE_API = "https://api.nileex.io";
export const SHASTA_API = "https://api.shasta.trongrid.io";

export const TOKEN_ADDRESS_MAINNET = "TEJApGsHLmWdVpfSAmz44j3ygjEwZbtFSo";
export const USDT_TOKEN_ADDRESS = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";
export const CROWDSALE_ADDRESS_MAINNET = "TQenYafz7uiMqtTCMg4KCwyMjRi8dy8RRU";
export const MOS_TOKEN_MAINNET = "TLgjFEnzmZZrDbD4pqZQ28xpfV3RxCwYkR";
export const UNISWAP_V2_MAINNET = "TKzxdSv2FZKQrEqkKVgp5DcwEXBEKMg2Ax";
export const MOS_USDT_PAIR_MAINNET = "TQ8wAc7bQEhRWxjuE6h8bzcgYg12kFesET";

export const TOKEN_ADDRESS_TESTNET = "TXduT3YVjrrYQUGpuq7MQKUtUdcYPdGH5j";
export const TEST_TOKEN_ADDRESS = "TXVj1NrsKC5mjwGAEo5c7EyfXyWNjHbr18";
export const SRT_CROWDSALE_ADDRESS = "TGofBgm7NmVtgUKkLHJ1thqLaLjNuyf6D5";

export const FOUNDATION_ADDRESS = "TJwzwSzqAxfAgt7oSvaTt3PPp6HSd4wEKG";
export const WEBSTORE_URL = "https://www.tronlink.org/";

export const EMPTY_ADDR = "410000000000000000000000000000000000000000";
export const MAX_ALLOWANCE_VALUE =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935";
export const TRX_DECIMAL = 6;

export const TRONWEB_STATUS = {
  1: {
    // wallet found
    installed: true,
    loggedIn: true,
    isCorrectNode: true,
    noWallet: false,
  },
  2: {
    // tronweb not looged in
    installed: true,
    loggedIn: false,
    isCorrectNode: false,
    noWallet: true,
  },
  3: {
    // connect to wrong node
    installed: true,
    loggedIn: true,
    isCorrectNode: false,
    noWallet: true,
  },
  4: {
    // cannot find tronweb
    installed: false,
    loggedIn: false,
    isCorrectNode: false,
    noWallet: true,
  },
};

export const SWAP_LIST = [
  {
    id: 1,
    token: "USDT",
    img: "/assets/USDT-01.svg",
    balance: 0,
  },
  {
    id: 2,
    token: "MOS",
    img: "/assets/mos-coin.svg",
    balance: 0,
  },
];

export const NETWORK_API_ARR_MAINNET = [
  "https://api.trongrid.io",
  "https://trx.mytokenpocket.vip",
];
export const NETWORK_API_ARR_TESTNET = [
  "https://api.nileex.io",
  "https://api.shasta.trongrid.io",
];

export const WALLET_SERVICE = "WALLET_SERVICE";

export const USDT = "USDT";
