import { combineReducers } from "redux";

import address from "./address";
import status from "./status";
import usdtBalance from "./usdtBalance";
import trxBalance from "./trxBalance";
import srtBalance from "./srtBalance";
import allowance from "./allowance";
import mosBalance from "./mosBalance";

export default combineReducers({
  address,
  status,
  usdtBalance,
  trxBalance,
  srtBalance,
  allowance,
  mosBalance,
});
