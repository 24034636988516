import Actions from '../../actions';
import { TRONWEB_STATUS } from '../../constant';

const getDefaultState = () => ({
    isLoading: false,
    data: null,
    error: null
})

function walletStatus(state, action) {
    if (typeof state === 'undefined') return getDefaultState();
    switch (action.type) {
        case Actions.UPDATE_WALLET_STATUS:
            return {
                isLoading: true,
                data: state.data,
                error: null
            }

        case Actions.UPDATE_WALLET_STATUS_SUCCESS:
            return {
                isLoading: false,
                data: {
                    ...TRONWEB_STATUS[action.data],
                    status: action.data
                },
                error: null,
            }

        case Actions.UPDATE_WALLET_STATUS_FAILED:
            return {
                isLoading: false,
                data: null,
                error: action.error
            }

        case Actions.RESET_WALLET_STATUS:
            return getDefaultState();

        default:
            return state;
    }
}

export default walletStatus;