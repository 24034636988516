import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";

import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_en from "./translations/en/common.json";
import common_cn from "./translations/cn/common.json";
import common_bm from "./translations/bm/common.json";
import common_bi from "./translations/bi/common.json";
import common_jp from "./translations/jp/common.json";
import common_th from "./translations/th/common.json";
import common_ko from "./translations/ko/common.json";
import common_vi from "./translations/vi/common.json";

import "./fonts/Lora-Bold.ttf";
import "./fonts/Lora-BoldItalic.ttf";
import "./fonts/Lora-Italic.ttf";
import "./fonts/Lora-Regular.ttf";
import "./fonts/SF-Pro-Italic.ttf";
import "./fonts/SF-Pro.ttf";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: window.localStorage.getItem("mos-lang") || "en", // language to use, falls back to en if language is not yet set
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    zh: {
      common: common_cn,
    },
    ms: {
      common: common_bm,
    },
    id: {
      common: common_bi,
    },
    ja: {
      common: common_jp,
    },
    th: {
      common: common_th,
    },
    ko: {
      common: common_ko,
    },
    vi: {
      common: common_vi,
    },
  },
  fallbackLng: ["en"],
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AlertProvider template={AlertTemplate}>
          <I18nextProvider i18n={i18next}>
            <App />
          </I18nextProvider>
        </AlertProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
