import * as status from "./status";
import * as address from "./address";
import * as usdtBalance from "./usdtBalance";
import * as trxBalance from "./trxBalance";
import * as srtBalance from "./srtBalance";
import * as allowance from "./allowance";
import * as mosBalance from "./mosBalance";

export default {
  ...status,
  ...address,
  ...usdtBalance,
  ...trxBalance,
  ...srtBalance,
  ...allowance,
  ...mosBalance,
};
