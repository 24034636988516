import React, { lazy, Suspense } from "react";
import {
  // BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import SpinnerLoading from "./components/Spinner/Spinner";

// React initial lazy loader
const loadable = (importFunc, { fallback = null } = { fallback: null }) => {
  const LazyComponent = lazy(importFunc);

  // eslint-disable-next-line react/display-name
  return (props) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

// const StakingComponent = loadable(() => import('./containers/Staking/Staking'), {
//     fallback: (
//         <div style={{ backgroundColor: '#191919', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//             <SpinnerLoading text="loading senatus round page" />
//         </div>
//     ),
// });

const HomeComponent = loadable(() => import("./containers/Home/Home"), {
  fallback: (
    <div
      style={{
        backgroundColor: "#191919",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <SpinnerLoading text="Loading Home Page" />
    </div>
  ),
});

const AboutComponent = loadable(() => import("./containers/About/About"), {
  fallback: (
    <div
      style={{
        backgroundColor: "#191919",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <SpinnerLoading text="Loading About Page" />
    </div>
  ),
});

const FeaturesComponent = loadable(
  () => import("./containers/Features/Features"),
  {
    fallback: (
      <div
        style={{
          backgroundColor: "#191919",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <SpinnerLoading text="Loading Features Page" />
      </div>
    ),
  }
);

const FaqComponent = loadable(() => import("./containers/Faq/Faq"), {
  fallback: (
    <div
      style={{
        backgroundColor: "#191919",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <SpinnerLoading text="Loading FAQ Page" />
    </div>
  ),
});

const WhitePaperComponent = loadable(
  () => import("./containers/Whitepaper/Whitepaper"),
  {
    fallback: (
      <div
        style={{
          backgroundColor: "#191919",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <SpinnerLoading text="Loading White Paper" />
      </div>
    ),
  }
);

const MigrateComponent = loadable(
  () => import("./containers/Migrate/Migrate"),
  {
    fallback: (
      <div
        style={{
          backgroundColor: "#191919",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <SpinnerLoading text="Loading Migration Page" />
      </div>
    ),
  }
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isScroll: false,
      isMobileHeader: Boolean(window.innerWidth < 1060),
      // isMobileInfiniteLoop: Boolean(window.innerWidth < 1100),
      deviceWidth: 0,
    };
  }

  onResize = () => {
    let width = window.innerWidth;
    if (this.state.isMobileHeader !== Boolean(width < 1060)) {
      this.setState({
        isMobileHeader: !this.state.isMobileHeader,
      });
    }
    this.setState({ deviceWidth: width });
  };

  onScroll = () => {
    this.setState({
      isScroll: Boolean(window.pageYOffset > 0),
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
    window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.onScroll);
  }

  render() {
    const { isScroll, isMobileHeader, deviceWidth } = this.state;
    // @todo Figure out a smarter way to route based on domain name
    if (window.location.host.split(".")[0] !== "sr") {
      return (
        <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => <HomeComponent isMobileHeader={isMobileHeader} />}
              />

              <Route
                exact
                path="/about"
                render={() => (
                  <AboutComponent isMobileHeader={isMobileHeader} />
                )}
              />

              <Route
                exact
                path="/features"
                render={() => (
                  <FeaturesComponent isMobileHeader={isMobileHeader} />
                )}
              />

              {/* <Route
                                exact
                                path="/senatus"
                                render={() =>
                                    <StakingComponent
                                        isScroll={isScroll}
                                        isMobileHeader={isMobileHeader}
                                        deviceWidth={deviceWidth}
                                    />
                                }
                            /> */}

              <Route
                exact
                path="/faq"
                render={() => <FaqComponent isMobileHeader={isMobileHeader} />}
              />

              <Route
                path="/whitepaper/"
                render={() => (
                  <WhitePaperComponent isMobileHeader={isMobileHeader} />
                )}
              />

              <Route
                exact
                path="/migration"
                render={() => (
                  <MigrateComponent isMobileHeader={isMobileHeader} />
                )}
              />
            </Switch>

            <Route exact path="/whitepaper">
              <Redirect to="/whitepaper/introduction" />
            </Route>
          </Suspense>
        </BrowserRouter>
      );
    }
    return (
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {/* <Route
                            exact
                            path="/"
                            render={() =>
                                <StakingComponent
                                    isScroll={isScroll}
                                    isMobileHeader={isMobileHeader}
                                    deviceWidth={deviceWidth}
                                />
                            }
                        /> */}

            <Route
              exact
              path="/"
              component={() => {
                window.location.href = "https://themos.io/";
                return null;
              }}
            />
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
